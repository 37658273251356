import React from 'react'
import Utils from '../utils/utils'
import {IGraphData, IStandingsData, IStandingsType} from '../types/types'

interface IStandingsProps {
	dayNumbers: number[]
	stats: IStandingsData[]
	mainLeaderboard: IStandingsType
}

class Standings extends React.Component<IStandingsProps, {}> {
	private stickyRefs: React.RefObject<HTMLTableHeaderCellElement>[]
	private tableRef: React.RefObject<HTMLTableElement>
	private nColumnsSticky = 6

	constructor(props: IStandingsProps) {
		super(props)
		this.stickyRefs = Array.from({length: this.nColumnsSticky}, () => React.createRef<HTMLTableHeaderCellElement>())
		this.tableRef = React.createRef<HTMLTableElement>()
	}

	componentDidMount() {
		this.setState({})
	}

	componentDidUpdate(prevProps: IStandingsProps) {
		if (this.props.mainLeaderboard !== prevProps.mainLeaderboard) {
			this.setStickyPositions()
		}
	}

	private setStickyPositions = () => {
		let left = 0;
		for (let i = 0; i < this.nColumnsSticky; i++) {
			const current = this.stickyRefs[i].current
			if (!current) {
				continue
			}

			const previousSiblingWidth = i === 0 ? undefined : this.stickyRefs[i-1].current?.offsetWidth
			left += previousSiblingWidth || 1
			const nodes = this.tableRef.current?.querySelectorAll<HTMLElement>(`tr td:nth-child(${i+1}), tr th:nth-child(${i+1})`)
			if (!nodes) {
				continue
			}

			for (const node of Array.from(nodes.values())) {
				node.style.left = `${left}px`
			}
		}
	}

	renderMedalTally = (data?: IGraphData[]) => {
		if (this.props.mainLeaderboard === 'time') {
			return <></>
		}

		const isGeneral = this.props.mainLeaderboard === 'general'
		if (!data) {
			return <>
				<th ref={this.stickyRefs[3]} scope="col" className="sticky" rowSpan={isGeneral ? 2 : 1}><img className="medal" src="./trophy-gold.png" alt="gold"/></th>
				<th ref={this.stickyRefs[4]} scope="col" className="sticky" rowSpan={isGeneral ? 2 : 1}><img className="medal" src="./trophy-silver.png" alt="silver"/></th>
				<th ref={this.stickyRefs[5]} scope="col" className="sticky" rowSpan={isGeneral ? 2 : 1}><img className="medal" src="./trophy-bronze.png" alt="bronze"/></th>
			</>
		}

		return <>
			<td className="right sticky">{Utils.countMemberPlaces(1, data, isGeneral)}</td>
			<td className="right sticky">{Utils.countMemberPlaces(2, data, isGeneral)}</td>
			<td className="right sticky">{Utils.countMemberPlaces(3, data, isGeneral)}</td>
		</>
	}

	render() {
		const dayNumbers = this.props.dayNumbers
		const isGeneral = this.props.mainLeaderboard === 'general'
		const isTime = this.props.mainLeaderboard === 'time'
		const stats = isTime ? [...this.props.stats].sort((a, b) => b.nCompleted - a.nCompleted || a.score - b.score) : [...this.props.stats].sort((a, b) => b.score - a.score)
		this.setStickyPositions()
		return (
			<div>
				<h2 className="glow">Standings</h2>
				<div className="table-container">
					<table ref={this.tableRef} className={isTime ? 'table-time' : ''}>
						<tbody>
							<tr>
								<th ref={this.stickyRefs[0]} scope="col" className="sticky"></th>
								<th ref={this.stickyRefs[1]} scope="col" className="sticky"></th>
								<th ref={this.stickyRefs[2]} scope="col" className="sticky"></th>
								{this.renderMedalTally()}
								{dayNumbers.map((day) => (
									<th scope="col" key={day} colSpan={isGeneral ? 2 : 1}>{day}</th>
								))}
							</tr>
							{isGeneral &&
								<tr>
									<th scope="col" className="sticky"></th>
									<th scope="col" className="sticky"></th>
									<th scope="col" className="sticky"></th>
									{dayNumbers.map((_, index) => (
										<React.Fragment key={index}>
											<th scope="col"><span className="star-silver">*</span></th>
											<th scope="col"><span className="star-gold">*</span></th>
										</React.Fragment>
									))}
								</tr>
							}
							{stats.map((member, index) => (
								<tr key={index}>
									<td className="sticky">{index+1})</td>
									<td className="sticky member">{member.name}</td>
									<td className="right sticky">{isTime ? Utils.scoreToTime(member.score) : member.score}</td>
									{this.renderMedalTally(member.data)}
									{dayNumbers.map((day, index) => (
										<React.Fragment key={index}>
											{isGeneral && <td className="center">{Utils.getStandingsCell(1, member.data, day, this.props.mainLeaderboard)}</td>}
											<td className="center">{Utils.getStandingsCell(2, member.data, day, this.props.mainLeaderboard)}</td>
										</React.Fragment>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}

export default Standings
