import React from 'react'
import {FlyoutProps, VictoryAxis, VictoryChart, VictoryGroup, VictoryLegend, VictoryLine, VictoryScatter, VictoryTooltip, VictoryZoomContainer} from 'victory'
import {IStandingsData, IStandingsType} from '../types/types'
import Utils from '../utils/utils'

class CustomFlyout extends React.Component<FlyoutProps, {}> {
	render() {
		const x = this.props.x
		const y = this.props.y
		const datum: {label: string[]} = this.props.datum as {label: string[]}
		const isTime = datum.label[5].toString() === 'time'
		const date = datum.label[0].toString()
		const day = datum.label[1].toString()
		const part = datum.label[2].toString()
		const position = isTime ? Utils.scoreToTime(parseInt(datum.label[3])) : datum.label[3].toString()
		const score = isTime ? Utils.scoreToTime(parseInt(datum.label[4])) : datum.label[4].toString()
		return (
			<g>
				<foreignObject x={x} y={y} width={90} height={90}>
					<div className={`tooltip${isTime && ' tooltip-wide'}`}>
						<div className="tooltip-container">
							<div className="tooltip-container">
								<div>Day {day} </div>
								<span className={part === '1' ? 'star-silver' : 'star-gold'}>*</span>
							</div>
							<div className="glow">+{position}</div>
						</div>
						<div className="tooltip-container">
							<div>{date}</div>
							<div className="glow">{score}</div>
						</div>
					</div>
				</foreignObject>
			</g>
		)
	}
}


interface IProgressionProps {
	stats: IStandingsData[]
	mainLeaderboard: IStandingsType
}

class Progression extends React.Component<IProgressionProps, {}> {
	render() {
		const memberData = [...this.props.stats].sort((a, b) => a.name.localeCompare(b.name))
		const maxScore = memberData
			.map((member) => member.data)
			.reduce((acc, member) => {
				let score = member[member.length-1].generalTotalScore
				if (this.props.mainLeaderboard === 'sprint') {
					score = member[member.length-1].sprintTotalScore || 0
				} else if (this.props.mainLeaderboard === 'time') {
					score = member[member.length-1].sprintTotalTime || 0
				}
				return score > acc ? score : acc
			}, 0)

		const colors = [
			'#e57373', // red 300
			'#7986cb', // indigo 300
			//'#ff8a65', // deepOrange 300
			'#81c784', // green 300
			'#4dd0e1', // cyan 300
			//'#ba68c8', // purple 300
			'#fff176'  // yellow 300
		]

		const data = []
		for (let i = 0; i < memberData.length; i++) {
			const lineData = this.props.mainLeaderboard === 'general' ? memberData[i].data : memberData[i].data.filter(d => d.part === 2)
			data.push(
				<VictoryGroup key={i}>
					<VictoryScatter
						name={`scatter-${i}`}
						data={lineData.map(elem => ({
							x: elem.time,
							y: Utils.getScore(elem, this.props.mainLeaderboard),
							label: [
								elem.date,
								elem.day,
								elem.part,
								Utils.getDayScore(elem, this.props.mainLeaderboard),
								Utils.getScore(elem, this.props.mainLeaderboard),
								this.props.mainLeaderboard
							]
						}))}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={<CustomFlyout/>}
								style={{
									display: 'none'
								}}
							/>
						}
						size={1.5}
						style={{
							data: {
								fill: colors[i]
							}
						}}
					/>
					<VictoryLine
						name={`line-${i}`}
						data={lineData.map(elem => ({x: elem.time, y: Utils.getScore(elem, this.props.mainLeaderboard)}))}
						style={{
							data: {
								stroke: colors[i],
								strokeWidth: 1
							},
						}}
					/>
				</VictoryGroup>
			)
		}

		// Legend
		const legendData = []
		for (let i = 0; i < memberData.length; i++) {
			legendData.push({
				name: memberData[i].name,
				symbol: {fill: colors[i]}
			})
		}

		return (
			<div>
				<h2 className="glow">Progression</h2>
				<VictoryChart containerComponent={<VictoryZoomContainer/>}>
					<VictoryAxis
						tickValues={[...Array(31).keys()].map(x => x+1)}
						style={{
							tickLabels: {
								fill: '#cccccc',
								fontFamily: '"Source Code Pro", monospace',
								fontSize: 8
							},
							grid: {
								stroke: 'rgb(28, 28, 28)',
								strokeWidth: 1
							}
						}}
					/>
					<VictoryAxis
						dependentAxis
						domain={[0, maxScore + (this.props.mainLeaderboard === 'time' ? 10000 : 10)]}
						tickFormat={(t) => this.props.mainLeaderboard === 'time' ? Utils.scoreToTime(t) : t}
						style={{
							tickLabels: {
								fill: '#cccccc',
								fontFamily: '"Source Code Pro", monospace',
								fontSize: 8
							}
						}}
					/>
					<VictoryLegend
						orientation="horizontal"
						gutter={20}
						centerTitle
						x={45}
						y={10}
						style={{
							labels: {
								fill: '#cccccc',
								fontFamily: '"Source Code Pro", monospace',
								fontSize: 6
							}
						}}
						data={legendData}
					/>
					{data}
				</VictoryChart>
			</div>
		)
	}
}

export default Progression
