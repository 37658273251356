import React from 'react'
import LeaderGeneral from './components/LeaderGeneral'
import LeaderPoints from './components/LeaderPoints'
import LeaderSprint from './components/LeaderSprint'
import Logs from './components/Logs'
import Progression from './components/Progression'
import Standings from './components/Standings'
import {IDaysData, ILogsData, IMemberData, IStandingsData, IStandingsType} from './types/types'
import Utils from './utils/utils'
import data from './data/final.json'

interface IAppState {
	days?: IDaysData
	dayNumbers?: number[]
	logs?: ILogsData[]
	memberData?: IMemberData[]
	stats?: IStandingsData[]
	mainLeaderboard: IStandingsType

	error?: string
	time?: number
}

class App extends React.Component<{}, IAppState> {
	constructor(props: {}) {
		super(props)
		this.state = {
			mainLeaderboard: 'general'
		}
	}

	componentDidMount() {
		const days = Utils.getDays(data)
		const dayNumbers = Utils.getDayNumbers(days)
		const logs = Utils.getLogs(data)
		const memberData = Utils.getProgression(data, days, dayNumbers)
		const stats = Utils.getStandings(memberData)

		if (!days || !dayNumbers || !memberData || !logs || !stats) {
			throw new Error()
		}

		this.setState({days, dayNumbers, memberData, logs, stats, error: undefined})
	}

	toggleMain = () => {
		let mainLeaderboard: IStandingsType
		switch (this.state.mainLeaderboard) {
			case 'general':
				mainLeaderboard = 'time'
				break
			case 'time':
				mainLeaderboard = 'sprint'
				break
			case 'sprint':
				mainLeaderboard = 'general'
				break
		}
		this.setState({mainLeaderboard})
	}

	render() {
		let content = undefined		
		if (this.state.error) {
			content = <div className="warning">{this.state.error}</div>
		} else if (this.state?.days
			&& this.state?.dayNumbers
			&& this.state?.memberData
			&& this.state?.logs
			&& this.state?.stats
		) {
			const general = this.state.stats
			const points = Utils.getStandingsPoints(this.state.memberData)
			const time = Utils.getStandingsSprint(this.state.memberData)

			let standings = [
				<LeaderPoints
					stats={points}
					mainLeaderboard={this.state.mainLeaderboard === 'sprint'}
				/>,
				<LeaderGeneral
					stats={general}
					mainLeaderboard={this.state.mainLeaderboard === 'general'}
				/>,
				<LeaderSprint
					sprint={time}
					mainLeaderboard={this.state.mainLeaderboard === 'time'}
				/>
			]

			let stats
			if (this.state.mainLeaderboard === 'sprint') {
				standings = [standings[2], standings[0], standings[1]]
				stats = points
			} else if (this.state.mainLeaderboard === 'time') {
				standings = [standings[1], standings[2], standings[0]]
				stats = time
			} else {
				stats = general
			}

			content = (
				<div className="container">
					<div className="top-container">
						{standings[0]}
						{standings[1]}
						{standings[2]}
					</div>
					<div className="bottom-container">
						<div className="left-container">
							<Standings
								stats={stats}
								dayNumbers={this.state.dayNumbers}
								mainLeaderboard={this.state.mainLeaderboard}
							/>
							<Progression
								stats={stats}
								mainLeaderboard={this.state.mainLeaderboard}
							/>
						</div>
						<div className="right-container">
							<Logs logs={this.state.logs} />
						</div>
					</div>
				</div>
			)
		} 

		let medal
		switch (this.state.mainLeaderboard) {
			case 'general':
				medal = <img className="medal" src="./trophy-gold.png" alt="gold" />
				break
			case 'time':
				medal = <img className="medal" src="./trophy-green.png" alt="green" />
				break
			case 'sprint':
				medal = <img className="medal" src="./trophy-dots.png" alt="dots" />
				break
		}
		return <>
			<h1 className="glow" onClick={this.toggleMain}>
				Advent of Code 2{medal}2{medal}
			</h1>
			<p>This <a className="glow" href="https://adventofcode.com/2020">2020 edition of Advent of Code</a> is now concluded. Even though no one else finished it was still a fun ride. Thanks my dudes!</p>
			<p className="protip">Protip: click on the header to change the main leaderboard.</p>
			{content}
		</>
	}
}

export default App
