import React from 'react'
import {ILogsData} from '../types/types'
import Utils from '../utils/utils'

interface ILogsProps {
	logs: ILogsData[]
}

class Logs extends React.Component<ILogsProps, {}> {
	render() {
		const logs = this.props.logs
		return (
			<div>
				<h2 className="glow">Logs</h2>
				{logs.map((log, index) => (
					<div key={index} className={log.glow ? 'glow' : ''}>[{Utils.formatDate(log.date)}] - {log.message}</div>
				))}
			</div>
		)
	}
}

export default Logs
