import {IDaysData, IGraphData, ILogsData, IMemberData, IRequestData, IStandingsData, IStandingsType} from '../types/types'

class Utils {
	static getDays = (data: IRequestData): IDaysData => {
		const days: IDaysData = {}
		for (const memberID in data.members) {
			const member = data.members[memberID]
			for (const dayString in member.completion_day_level) {
				const dayInt = parseInt(dayString)
				const userStats = member.completion_day_level[dayString]
				const dayData = dayInt in days ? days[dayInt] : {1: {}, 2: {}}
				if (userStats['1']) {
					dayData[1] = {
						...dayData[1],
						[memberID]: {
							get_star_ts: userStats['1'].get_star_ts
						}
					}
				}
				
				if (userStats['2']) {
					dayData[2] = {
						...dayData[2],
						[memberID]: {
							get_star_ts: userStats['2'].get_star_ts
						}
					}
				}
				days[dayInt] = dayData
			}
		}
		return Utils.setPartStandings(days)
	}

	static getLogs = (data: IRequestData): ILogsData[] => {
		const logs: ILogsData[] = []
		for (const memberID in data.members) {
			const member = data.members[memberID]
			for (const dayString in member.completion_day_level) {
				const dayInt = parseInt(dayString)
				const userStats = member.completion_day_level[dayString]
				if (userStats['1']) {
					logs.push({
						date: new Date(parseInt(userStats['1'].get_star_ts)*1000),
						message: <span><span className="member">{member.name}</span> solved day {dayInt} part 1</span>
					})
				}
				if (userStats['2']) {
					logs.push({
						date: new Date(parseInt(userStats['2'].get_star_ts)*1000),
						message: <span><span className="member">{member.name}</span> solved day {dayInt} part 2</span>
					})
				}
			}
		}

		const now = new Date()
		let day = new Date(1606798800000)
		let currentDay = 1
		do {
			logs.push({
				date: day,
				message: <span>Day {currentDay} started</span>,
				glow: true
			})
			currentDay++
			const previousDay = day.getDate()
			day = new Date(day)
			day.setDate(previousDay + 1)
		} while (day.getTime() <= now.getTime() && currentDay <= 25)
		return [...logs].sort((a, b) => b.date.getTime() - a.date.getTime())
	}

	private static setPartStandings = (daysData: IDaysData): IDaysData => {
		for (const day in daysData) {
			const dayStats = daysData[day]
			for (const part in dayStats) {
				const part1 = dayStats[1]
				const partStats = dayStats[part]
				const positions = Object.entries(partStats).sort((a, b) => parseInt(a[1].get_star_ts) - parseInt(b[1].get_star_ts))
				for (let i = 0; i < positions.length; i++) {
					partStats[positions[i][0]].position = i + 1
					if (part === '2' && '1' in dayStats) {
						partStats[positions[i][0]].sprintTime = parseInt(partStats[positions[i][0]].get_star_ts) - parseInt(part1[positions[i][0]].get_star_ts)
					}
				}
			}
		}
		for (const day in daysData) {
			const dayStats = daysData[day]
			const partStats = dayStats[2]
			if (!partStats) {
				continue
			}

			let partStatsEntries = Object.entries(partStats)
			partStatsEntries.filter((elem) => elem[1].sprintTime !== undefined)
			const positions = [...partStatsEntries].sort((a, b) => (a[1].sprintTime as number) - (b[1].sprintTime as number))
			for (let i = 0; i < positions.length; i++) {
				positions[i][1].sprintPosition = i + 1
			}
		}
		return daysData
	}

	static getProgression = (data: IRequestData, days: IDaysData, dayNumbers: number[]): IMemberData[] => {
		const progressions = []

		const members = Object.values(data.members)
		for (const member of members) {
			let graphData: IGraphData[] = []
			for (const day of dayNumbers) {
				const part1 = Utils.getProgressionItem(day, 1, members.length, member.id, days)
				if (part1) {
					graphData.push(part1)
				}

				const part2 = Utils.getProgressionItem(day, 2, members.length, member.id, days)
				if (part2) {
					graphData.push(part2)
				}
			}

			graphData.sort((a, b) => a.time - b.time)
			for (let i = 1; i < graphData.length; i++) {
				graphData[i].generalTotalScore += graphData[i-1].generalTotalScore
				graphData[i].sprintTotalScore = (graphData[i].sprintTotalScore || 0) + (graphData[i-1].sprintTotalScore || 0)
				graphData[i].sprintTotalTime = (graphData[i].sprintTotalTime || 0) + (graphData[i-1].sprintTotalTime || 0)
			}
			progressions.push({id: member.id, name: member.name, data: graphData})
		}

		return progressions
	}

	static getProgressionItem = (day: number, part: number, nMembers: number, memberID: string, days: IDaysData): IGraphData | null => {
		const position = days[day][part][memberID]?.position
		const sprintPosition = days[day][part][memberID]?.sprintPosition || nMembers + 1
		const sprintTime = days[day][part][memberID]?.sprintTime || 0
		const time = days[day][part][memberID]?.get_star_ts
		if (position === undefined || time === undefined) {
			return null
		}

		const now = new Date(parseInt(time)*1000)
		const start = new Date(now)
		start.setHours(5)
		start.setMinutes(0)
		start.setSeconds(0)
		start.setMilliseconds(0)
		const end = new Date(now)
		end.setHours(5)
		end.setMinutes(0)
		end.setSeconds(0)
		end.setMilliseconds(0)
		end.setDate(now.getDate() + 1)

		const x = now.getDate() + 1 -
			((end.getTime() - parseInt(time)*1000)
			/
			(end.getTime() - start.getTime()))
		const y = nMembers - position + 1
		const date = Utils.formatTime(new Date(parseInt(time)*1000))
		const sprintDayScore = nMembers - sprintPosition + 1

		return {
			date,
			day,
			part,
			generalPosition: position,
			generalDayScore: y,
			generalTotalScore: y,
			sprintPosition,
			sprintDayScore,
			sprintTotalScore: sprintDayScore,
			sprintTime,
			sprintTotalTime: sprintTime,
			time: x
		}
	}

	static getStandings = (memberData: IMemberData[]): IStandingsData[] => {
		return memberData
			.map((member) => ({
				id: member.id,
				name: member.name,
				data: member.data,
				score: member.data.reduce((acc, elem) => elem.generalTotalScore > acc ? elem.generalTotalScore : acc, 0),
				nCompleted: member.data.length
			}))
			.sort((a, b) => b.score - a.score)
	}

	static getStandingsPoints = (memberData: IMemberData[]): IStandingsData[] => {
		return memberData
			.map((member) => ({
				id: member.id,
				name: member.name,
				data: member.data,
				score: member.data.reduce((acc, elem) => elem.sprintTotalScore && elem.sprintTotalScore > acc ? elem.sprintTotalScore : acc, 0),
				nCompleted: member.data.length
			}))
			.sort((a, b) => b.score - a.score)
	}

	static getStandingsSprint = (memberData: IMemberData[]): IStandingsData[] => {
		return memberData
			.map((member) => ({
				id: member.id,
				name: member.name,
				data: member.data,
				score: member.data.reduce((acc, elem) => (elem.sprintTime || 0) + acc, 0),
				nCompleted: member.data.length
			}))
			.sort((a, b) => b.nCompleted - a.nCompleted || a.score - b.score)
	}

	static countMemberPlaces = (place: number, data: IGraphData[], isGeneral: boolean): number => {
		return isGeneral ? Utils.countPlaces(place, 1, data, isGeneral) + Utils.countPlaces(place, 2, data, isGeneral) : Utils.countPlaces(place, 2, data, isGeneral)
	}

	private static countPlaces = (place: number, part: number, data: IGraphData[], isGeneral: boolean): number => {
		return isGeneral ? data.filter(d => d.part === part && d.generalPosition === place).length : data.filter(d => d.part === part && d.sprintPosition === place).length
	}

	static getStandingsCell = (part: number, data: IGraphData[], day: number, leaderboard: IStandingsType) => {
		const cell = data.filter(d => d.day === day && d.part === part)
		let position = undefined
		if (cell.length === 1) {
			switch(leaderboard) {
				case 'general':
				case 'sprint':
					position = leaderboard === 'general' ? cell[0].generalPosition : cell[0].sprintPosition
					switch(position) {
						case 1: return <img className="medal" src="./trophy-gold.png" alt="gold"/>
						case 2: return <img className="medal" src="./trophy-silver.png" alt="silver"/>
						case 3: return <img className="medal" src="./trophy-bronze.png" alt="bronze"/>
						default: return position
					}
				case 'time':
					return Utils.scoreToTime(cell[0].sprintTime || 0)
			}
		}
	}

	static formatDate = (date: Date) => {
		return `${date.getFullYear()}-${Utils.padNumber(date.getMonth() + 1, 2)}-${Utils.padNumber(date.getDate(), 2)} ${Utils.padNumber(date.getHours(), 2)}:${Utils.padNumber(date.getMinutes(), 2)}:${Utils.padNumber(date.getSeconds(), 2)}`
	}

	static formatTime = (date: Date) => {
		return `${Utils.padNumber(date.getHours(), 2)}:${Utils.padNumber(date.getMinutes(), 2)}:${Utils.padNumber(date.getSeconds(), 2)}`
	}

	private static padNumber = (n: number, maxLength: number) => {
		return n.toString().padStart(maxLength, '0')
	}

	static getDayNumbers = (days: IDaysData) => {
		return Object.keys(days).map((i) => parseInt(i))
	}

	static scoreToTime = (score: number) => {
		return `${Math.floor(score/3600).toString().padStart(2, '0')}:${Math.floor(score%3600/60).toString().padStart(2, '0')}:${(score%60).toString().padStart(2, '0')}`
	}

	static getScore = (data: IGraphData, mainLeaderboard: IStandingsType) => {
		let score = data.generalTotalScore
		if (mainLeaderboard === 'sprint') {
			score = data.sprintTotalScore || 0
		} else if (mainLeaderboard === 'time') {
			score = data.sprintTotalTime
		}
		return score
	}

	static getDayScore = (data: IGraphData, mainLeaderboard: IStandingsType) => {
		let position = data.generalDayScore
		if (mainLeaderboard === 'sprint') {
			position = data.sprintDayScore || 0
		} else if (mainLeaderboard === 'time') {
			position = data.sprintTime || 0
		}
		return position
	}
}

export default Utils
