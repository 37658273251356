import React from 'react'
import {IStandingsData} from '../types/types'

interface ILeaderPointsProps {
	stats: IStandingsData[]
	mainLeaderboard: boolean
}

class LeaderPoints extends React.Component<ILeaderPointsProps, {}> {
	render() {
		const stats = this.props.stats
		const maxCompleted = stats.reduce((acc, member) => member.nCompleted > acc ? member.nCompleted : acc, 0)
		return (
			<div>
				{this.props.mainLeaderboard ? (
					<h2 className="glow">Sprint points</h2>
				) : (
					<h3 className="glow">Sprint points</h3>
				)}
				<table className={this.props.mainLeaderboard ? '' : 'table-sm'}>
					<tbody>
						{stats.map((member, index) => (
							<tr key={index}>
								<td>{
									index === 0 ? <img className="medal" src="./trophy-dots.png" alt="dots"/> :
									`${index+1})`
								}</td>
								<td className="member">{member.name}</td>
								<td className="not-solved">{member.nCompleted < maxCompleted ? `-${maxCompleted - member.nCompleted}` : ''}</td>
								<td className="right">{member.score}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)
	}
}

export default LeaderPoints
