import React from 'react'
import {IStandingsData} from '../types/types'
import Utils from '../utils/utils'

interface ILeaderSprintProps {
	sprint: IStandingsData[]
	mainLeaderboard: boolean
}

class LeaderSprint extends React.Component<ILeaderSprintProps, {}> {
	render() {
		const sprint = this.props.sprint
		const maxCompleted = sprint.reduce((acc, member) => member.nCompleted > acc ? member.nCompleted : acc, 0)
		return (
			<div>
				{this.props.mainLeaderboard ? (
					<h2 className="glow">Sprint time</h2>
				) : (
					<h3 className="glow">Sprint time</h3>
				)}
				<table className={this.props.mainLeaderboard ? '' : 'table-sm'}>
					<tbody>
						{sprint.map((member, index) => (
							<tr key={index}>
								<td>{
									index === 0 ? <img className="medal" src="./trophy-green.png" alt="green"/> :
									`${index+1})`
								}</td>
								<td className="member">{member.name}</td>
								<td className="not-solved">{member.nCompleted < maxCompleted ? `-${maxCompleted - member.nCompleted}` : ''}</td>
								<td className="right">{Utils.scoreToTime(member.score)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)
	}
}

export default LeaderSprint
