import React from 'react'
import {IStandingsData} from '../types/types'

interface ILeaderGeneralProps {
	stats: IStandingsData[]
	mainLeaderboard: boolean
}

class LeaderGeneral extends React.Component<ILeaderGeneralProps, {}> {
	render() {
		const stats = this.props.stats
		const maxCompleted = stats.reduce((acc, member) => member.nCompleted > acc ? member.nCompleted : acc, 0)
		return (
			<div>
				{this.props.mainLeaderboard ? (
					<h2 className="glow">General</h2>
				) : (
					<h3 className="glow">General</h3>
				)}
				<table className={this.props.mainLeaderboard ? '' : 'table-sm'}>
					<tbody>
						{stats.map((member, index) => (
							<tr key={index}>
								<td>{
									index === 0 ? <img className="medal" src="./trophy-gold.png" alt="gold"/> :
									index === 1 ? <img className="medal" src="./trophy-silver.png" alt="silver"/> :
									index === 2 ? <img className="medal" src="./trophy-bronze.png" alt="bronze"/> :
									`${index+1})`
								}</td>
								<td className="member">{member.name}</td>
								<td className="not-solved">{member.nCompleted < maxCompleted ? `-${maxCompleted - member.nCompleted}` : ''}</td>
								<td className="right">{member.score}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)
	}
}

export default LeaderGeneral
